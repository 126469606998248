@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500&display=swap');
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "styles/base/print.pcss";
@import "styles/base/typography.pcss";
@import "styles/base/structure.pcss";
@import "styles/base/rich-text.pcss";

@import "styles/components/circle-graph.pcss";
@import "styles/components/answer.pcss";
@import "styles/components/elements.pcss";