@import "https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@300;400;500&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: Graphik, Hanken Grotesk, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b, strong {
  font-weight: 500;
}

.header-container {
  width: 100%;
  max-width: 1512px;
  margin-left: auto;
  margin-right: auto;
}

.base-container {
  width: 100%;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.question-container {
  width: 100%;
  max-width: 874px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.rich-text p {
  margin-bottom: 1.25rem;
  font-size: .875rem;
  line-height: 1.125rem;
}

@media (min-width: 800px) {
  .rich-text p {
    font-size: 1rem;
    line-height: 1.3125rem;
  }
}

.rich-text ol {
  margin-bottom: .5rem;
  margin-left: 5rem;
  font-size: 1rem;
  line-height: 1.3125rem;
  list-style-type: decimal;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 800px) {
  .container {
    max-width: 800px;
  }
}

.bar path:nth-child(1), .bar path:nth-child(2), .bar path:nth-child(3), .bar path:nth-child(4) {
  opacity: 0;
}

.bar.points5 path:nth-child(1), .bar.points5 path:nth-child(2), .bar.points5 path:nth-child(3), .bar.points5 path:nth-child(4) {
  opacity: 1;
}

.bar.points4 path:nth-child(1) {
  fill: #e3e3e3;
  opacity: 1;
}

.bar.points4 path:nth-child(2), .bar.points4 path:nth-child(3), .bar.points4 path:nth-child(4) {
  opacity: 1;
}

.bar.points3 path:nth-child(1), .bar.points3 path:nth-child(2) {
  fill: #e3e3e3;
  opacity: 1;
}

.bar.points3 path:nth-child(3), .bar.points3 path:nth-child(4) {
  opacity: 1;
}

.bar.points2 path:nth-child(1), .bar.points2 path:nth-child(2), .bar.points2 path:nth-child(3) {
  fill: #e3e3e3;
  opacity: 1;
}

.bar.points2 path:nth-child(4) {
  opacity: 1;
}

.bar.points1 path:nth-child(1), .bar.points1 path:nth-child(2), .bar.points1 path:nth-child(3), .bar.points1 path:nth-child(4) {
  fill: #e3e3e3;
  opacity: 1;
}

.answers {
  margin-left: 1.25rem;
  list-style-type: none;
}

.answers:first-child {
  counter-reset: customlistcounter;
}

.answers .answer {
  position: relative;
}

.answers .answer.showNum {
  counter-increment: customlistcounter;
}

.answers .answer.showNum:before {
  width: .625rem;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.125rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1.5rem;
}

@media (min-width: 800px) {
  .answers .answer.showNum:before {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}

.answers .answer.showNum:before {
  content: counter(customlistcounter) " ";
}

.answers .answer.dash:before {
  width: .625rem;
  align-items: center;
  font-size: .875rem;
  font-weight: 500;
  line-height: 1.125rem;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1.5rem;
}

@media (min-width: 800px) {
  .answers .answer.dash:before {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
}

.answers .answer.dash:before {
  content: "-";
}

.answers .answer label {
  min-height: 3rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  letter-spacing: -.025em;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
  border-radius: 1rem;
  flex-direction: column;
  justify-content: center;
  padding: .5rem;
  font-size: .75rem;
  line-height: 1rem;
  display: flex;
}

.answers .answer label:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 227 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

@media (min-width: 800px) {
  .answers .answer label {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.3125rem;
  }
}

.answers .answer input {
  z-index: -10;
  opacity: 0;
  position: absolute;
}

.answers .answer input:checked + label {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.answers .answer input:focus + label {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.arrow {
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-color: transparent;
  background-color: #0000;
  border-radius: 1rem;
  align-items: center;
  gap: 2rem;
  padding: 11px 1rem;
  font-size: .875rem;
  line-height: 14px;
  display: flex;
}

.arrow:hover, .arrow:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 227 / var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
}

.arrow.arrow-left:before {
  content: url("arrow-left.a06f223c.svg");
  position: static;
}

.arrow.arrow-right:after {
  content: url("arrow-right.16113236.svg");
  position: static;
}

.button {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity));
  border-radius: .25rem;
  padding: .5rem 1rem;
  display: inline-block;
}

.button:hover, .button:focus {
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 227 / var(--tw-bg-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.clear-both {
  clear: both;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.my-26 {
  margin-top: 6.5rem;
  margin-bottom: 6.5rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.h-6 {
  height: 1.5rem;
}

.h-\[255px\] {
  height: 255px;
}

.h-\[40px\] {
  height: 40px;
}

.h-\[56px\] {
  height: 56px;
}

.h-\[650px\] {
  height: 650px;
}

.h-\[72px\] {
  height: 72px;
}

.w-4 {
  width: 1rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-8 {
  width: 2rem;
}

.w-\[172px\] {
  width: 172px;
}

.w-\[356px\] {
  width: 356px;
}

.w-\[390px\] {
  width: 390px;
}

.w-\[57px\] {
  width: 57px;
}

.w-fit {
  width: -moz-fit-content;
  width: fit-content;
}

.max-w-\[450px\] {
  max-width: 450px;
}

.border-separate {
  border-collapse: separate;
}

.border-spacing-1 {
  --tw-border-spacing-x: .25rem;
  --tw-border-spacing-y: .25rem;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y);
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-2 {
  gap: .5rem;
}

.border-l {
  border-left-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r {
  border-right-width: 1px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-l-red {
  --tw-border-opacity: 1;
  border-left-color: rgb(163 46 46 / var(--tw-border-opacity));
}

.border-l-turquoise {
  --tw-border-opacity: 1;
  border-left-color: rgb(33 121 151 / var(--tw-border-opacity));
}

.border-r-blue {
  --tw-border-opacity: 1;
  border-right-color: rgb(78 80 212 / var(--tw-border-opacity));
}

.border-r-orange {
  --tw-border-opacity: 1;
  border-right-color: rgb(199 125 36 / var(--tw-border-opacity));
}

.bg-beige {
  --tw-bg-opacity: 1;
  background-color: rgb(234 217 208 / var(--tw-bg-opacity));
}

.bg-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(78 80 212 / var(--tw-bg-opacity));
}

.bg-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(227 227 227 / var(--tw-bg-opacity));
}

.bg-orange {
  --tw-bg-opacity: 1;
  background-color: rgb(199 125 36 / var(--tw-bg-opacity));
}

.bg-red {
  --tw-bg-opacity: 1;
  background-color: rgb(163 46 46 / var(--tw-bg-opacity));
}

.bg-turquoise {
  --tw-bg-opacity: 1;
  background-color: rgb(33 121 151 / var(--tw-bg-opacity));
}

.p-4 {
  padding: 1rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pt-20 {
  padding-top: 5rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-base {
  font-size: 1rem;
  line-height: 1.3125rem;
}

.text-circle-label {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-label-number-mobile {
  font-size: .5rem;
  line-height: .5rem;
}

.text-question {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-question-label {
  font-size: 1.25rem;
  line-height: 1.625rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.125rem;
}

.text-xs {
  font-size: .8125rem;
  line-height: 1.0625rem;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.text-blue {
  --tw-text-opacity: 1;
  color: rgb(78 80 212 / var(--tw-text-opacity));
}

.text-orange {
  --tw-text-opacity: 1;
  color: rgb(199 125 36 / var(--tw-text-opacity));
}

.text-red {
  --tw-text-opacity: 1;
  color: rgb(163 46 46 / var(--tw-text-opacity));
}

.text-turquoise {
  --tw-text-opacity: 1;
  color: rgb(33 121 151 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

body {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

h1:focus, h2:focus {
  outline-width: 0;
  outline-style: solid;
}

@media print {
  .print\:mt-0 {
    margin-top: 0;
  }

  .print\:mt-10 {
    margin-top: 2.5rem;
  }

  .print\:hidden {
    display: none;
  }

  .print\:max-h-\[475px\] {
    max-height: 475px;
  }

  .print\:w-\[454px\] {
    width: 454px;
  }

  .print\:max-w-\[800px\] {
    max-width: 800px;
  }

  .print\:text-lg {
    font-size: 1.125rem;
    line-height: 1.5625rem;
  }
}

@media (min-width: 800px) {
  .desktop\:mb-20 {
    margin-bottom: 5rem;
  }

  .desktop\:mt-0 {
    margin-top: 0;
  }

  .desktop\:mt-4 {
    margin-top: 1rem;
  }

  .desktop\:block {
    display: block;
  }

  .desktop\:hidden {
    display: none;
  }

  .desktop\:h-12 {
    height: 3rem;
  }

  .desktop\:h-\[104px\] {
    height: 104px;
  }

  .desktop\:h-\[120px\] {
    height: 120px;
  }

  .desktop\:h-\[419px\] {
    height: 419px;
  }

  .desktop\:h-\[475px\] {
    height: 475px;
  }

  .desktop\:w-16 {
    width: 4rem;
  }

  .desktop\:w-8 {
    width: 2rem;
  }

  .desktop\:w-\[240px\] {
    width: 240px;
  }

  .desktop\:w-\[454px\] {
    width: 454px;
  }

  .desktop\:w-\[640px\] {
    width: 640px;
  }

  .desktop\:w-\[816px\] {
    width: 816px;
  }

  .desktop\:max-w-\[800px\] {
    max-width: 800px;
  }

  .desktop\:p-8 {
    padding: 2rem;
  }

  .desktop\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .desktop\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .desktop\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .desktop\:text-label-number-desktop {
    font-size: .625rem;
    line-height: .625rem;
  }

  .desktop\:text-table {
    font-size: 1.5rem;
    line-height: 3rem;
  }

  .desktop\:text-title1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  .desktop\:text-title2 {
    font-size: 2.25rem;
    line-height: 3rem;
  }

  .desktop\:text-xl {
    font-size: 2rem;
    line-height: 2.6875rem;
  }
}

/*# sourceMappingURL=index.9ba0ae70.css.map */
